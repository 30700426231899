import { ConcentrationsData, RunConcentration } from './interface/RunConcentration';
import { SubTree, Tree } from './interface/Menu';

export default class Concentrations implements ConcentrationsData {
  professional: RunConcentration[] = [];
  bachelor: RunConcentration[] = [];
  language: RunConcentration[] = [];

  constructor (data: ConcentrationsData) {
    this.professional = data.professional;
    this.bachelor = data.bachelor;
    this.language = data.language;
  }

  hasMoreThanOneConcentration (): boolean {
    let count = 0;
    count += this.professional.length;
    count += this.bachelor.length;
    count += this.language.length;
    return count > 1;
  }

  dataAsArray (): Record<string, RunConcentration[]> {
    return {
      professional: this.professional,
      bachelor: this.bachelor,
      language: this.language,
    };
  }

  dataAsFlatArray (): RunConcentration[] {
    let array: RunConcentration[] = [];
    array = array.concat(this.bachelor);
    array = array.concat(this.professional);
    array = array.concat(this.language);
    return array;
  }

  menu (): Record<'all'|string, Tree> {
    const allChildren = this.convertConcentrationToTree(this.dataAsFlatArray());
    const allTree: Tree = {
      children: allChildren,
      hasChildren: allChildren.length > 0,
      modifier: 'all',
      translatable: 'submenu.all',
    };

    return {
      all: allTree,
    };
  }

  convertConcentrationToTree (values: RunConcentration[]): SubTree[] {
    const subTree: SubTree[] = [];

    values.forEach((concentration) => {
      subTree.push({
        children: [],
        code: concentration.code,
        hasChildren: false,
        id: concentration.id,
        label: concentration.title,
        type: concentration.programmeType,
        startDate: new Date(concentration.startDate || 'now'),
        endDate: new Date(concentration.endDate || 'now'),
      });
    });

    return subTree.sort((node1, node2) => (node2.startDate.getTime() - node1.startDate.getTime()));
  }
}
