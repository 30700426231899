<template lang="pug">
  .jwl-profile-image
    img.jwl-profile-image__image(ref="profileImage" width="75" height="75" v-show="!showFallback")
    .jwl-profile-image__fallback(v-if="showFallback")
      icon-user
</template>

<script>
const IconUser = () => import('../assets/fa-icons/duotone/user.svg');

export default {
  name: 'jwl-profile-image',
  components: {
    IconUser,
  },
  props: {
    imageUrl: String,
    imageBlob: null,
  },
  data () {
    return {
      showFallback: true,
    };
  },
  methods: {
    fetchImage () {
      this.$store.getters.api.axios.get(this.imageUrl, {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          const img = this.$refs.profileImage;
          img.onload = () => {
            URL.revokeObjectURL(img.src);
          };
          img.src = URL.createObjectURL(blob);

          this.showFallback = false;
        });
    },
  },
  mounted () {
    if (this.imageUrl) {
      this.fetchImage();
    }
  },
};
</script>

<style lang="sass">
@import 'src/styles/variables'

.jwl-profile-image
  background-color: $gray-400
  border-radius: 50%
  display: grid
  font-size: 3.25rem
  grid-template-columns: 100%
  grid-template-rows: 100%
  height: 4.7rem
  overflow: hidden
  width: 4.7rem

  &__image,
  &__fallback
    align-items: center
    display: flex
    grid-column: 1
    grid-row: 1
    height: 100%
    justify-content: center
    width: 100%

  &__fallback
    color: $gray-700
    font-size: 1em
</style>
