



















import { mapGetters } from 'vuex';
import CommonHeader from 'common-modules/src/components/CommonHeader.vue';
import CommonFooter from 'common-modules/src/components/CommonFooter.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserInterface } from 'common-modules/src/store/interface/User';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

const ALLOWED_PATHS = ['login', 'handle_login'];

@Component({
  components: {
    CommonHeader,
    CommonFooter,
    CommonError,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class App extends Vue {
  currentUser!: UserInterface;

  role: string | null = null;

  handleLoginRedirect (): void {
    this.$router.push({
      name: 'home',
    });
  }

  get isLmsOnly (): boolean {
    const nonSisRoles = ['friend', 'student', 'facilitator'];
    return !!this.role && nonSisRoles.includes(this.role);
  }

  get showHeader (): boolean {
    const currentRoute = this.$route;
    return !!currentRoute
      && !!currentRoute.name
      && !ALLOWED_PATHS.includes(currentRoute.name)
      && !!this.currentUser;
  }

  get showView (): boolean {
    return !!this.currentUser
      || (this.$route.name && ALLOWED_PATHS.includes(this.$route.name));
  }

  mounted (): void {
    this.$store.dispatch('getBaseData')
      .then((role) => {
        this.role = role;
      })
      .catch(() => {
        this.$router.push({
          name: 'login',
        });
      });
  }
}
