import routerView from 'common-modules/src/components/CommonRouterView.vue';
import { Route, RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/:lang/handle-login',
    name: 'handle_login',
    component: () => import('common-modules/src/components/CommonHandleLogin.vue'),
  },
  {
    path: '/:lang/login',
    name: 'login',
    component: () => import('common-modules/src/components/CommonLogin.vue'),
  },
  {
    path: '/:lang',
    name: 'lang',
    component: routerView,
    redirect: (to: Route) => ({ name: 'home', lang: to.params.lang }),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/JWL_Home.vue'),
      },
      {
        path: 'student',
        name: 'student',
        component: routerView,
        redirect: (to: Route) => ({ name: 'studentList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'studentList',
            component: () => import('@/views/JWL_Student.vue'),
          },
          {
            path: ':student/overview',
            name: 'studentOverview',
            component: () => import('@/views/student/SIS_StudentOverview.vue'),
          },
        ],
      },
      {
        path: 'facilitator',
        name: 'facilitator',
        component: routerView,
        redirect: (to: Route) => ({ name: 'facilitatorList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'facilitatorList',
            component: () => import('@/views/JWL_Facilitator.vue'),
          },
          {
            path: ':facilitator/edit',
            name: 'facilitatorEdit',
            component: () => import('@/views/facilitator/JWL_FacilitatorEdit.vue'),
          },
        ],
      },
      {
        path: 'learning-group',
        name: 'learningGroup',
        component: routerView,
        redirect: (to: Route) => ({ name: 'learningGroupList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'learningGroupList',
            component: () => import('@/views/SIS_LearningGroup.vue'),
          },
          {
            path: 'new',
            name: 'learningGroupNew',
            component: () => import('@/views/learningGroup/SIS_LearningGroupEdit.vue'),
          },
          {
            path: ':clc/edit',
            name: 'learningGroupEdit',
            component: () => import('@/views/learningGroup/SIS_LearningGroupEdit.vue'),
          },
        ],
      },
      {
        path: 'programme',
        name: 'programme',
        component: routerView,
        redirect: (to: Route) => ({ name: 'programmeProfessionalList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'programmeList',
            component: () => import('@/views/JWL_Programme.vue'),
            redirect: (to) => ({ name: 'programmeProfessionalList', lang: to.params.lang }),
            children: [
              {
                path: 'professional-certificate',
                name: 'programmeProfessionalList',
                component: () => import('@/views/programme/SIS_ProgrammeList.vue'),
              },
            ],
          },
          {
            path: 'professional-certificate',
            name: 'programmeProfessionalRoot',
            component: routerView,
            children: [
              {
                path: 'new',
                name: 'programmeNew',
                component: () => import('@/views/programme/SIS_ProgrammeEdit.vue'),
              },
              {
                path: ':programme/overview',
                name: 'programmeOverview',
                component: () => import('@/views/programme/SIS_ProgrammeOverview.vue'),
              },
              {
                path: ':programme/edit',
                name: 'programmeEdit',
                component: () => import('@/views/programme/SIS_ProgrammeEdit.vue'),
              },
              {
                path: ':programme/classes',
                name: 'programmeClasses',
                component: () => import('@/views/programme/SIS_ProgrammeClasses.vue'),
              },
              {
                path: ':programme/finish',
                name: 'programmeFinish',
                component: () => import('@/views/programme/JWL_ProgrammeFinish.vue'),
              },
              {
                path: ':programme/certificate-export',
                name: 'programmeCertificateExport',
                component: () => import('@/views/programme/JWL_ProgrammeCertificateExport.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'user',
        name: 'user',
        component: routerView,
        redirect: (to: Route) => ({ name: 'userList', lang: to.params.lang }),
        children: [
          {
            path: 'import',
            name: 'userImport',
            component: () => import('@/views/user/SIS_UserImport.vue'),
          },
          {
            path: 'list',
            name: 'userList',
            component: () => import('../views/JWL_User.vue'),
          },
          {
            path: ':user/edit',
            name: 'userEdit',
            component: () => import('../views/user/JWL_UserEdit.vue'),
          },
        ],
      },
      {
        path: 'undo-publish-requests',
        name: 'undoPublish',
        component: () => import('../views/JWL_UndoPublish.vue'),
      },
    ],
  },
];

export default routes;
