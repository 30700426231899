import Vue from 'vue';
import Vuex, {
  ActionContext, ActionTree, GetterTree, ModuleTree, MutationTree,
} from 'vuex';
import moduleStore, { api, handleErrorResponse, ModuleState } from 'common-modules/src/store/moduleStore';
import { AxiosError } from 'axios';

Vue.use(Vuex);

export interface SisState {
  locale: string,
}

const sisState: SisState = {
  locale: 'en',
};

const getters: GetterTree<SisState, SisState> = {
  api: () => api,
  locale: (state) => state.locale,
};

interface MutationInterface<S = SisState> {
  SET_LOCALE (state: S, locale: string): void;
  HANDLE_LOGIN(state: S, payload: { token: string, refreshToken: string }): void;
}

const mutations: MutationTree<SisState> & MutationInterface = {
  SET_LOCALE (state, locale) {
    state.locale = locale;
  },
  HANDLE_LOGIN (state, data) {
    api.login(data.token, data.refreshToken);
  },
};

type AugmentedActionContext = {
  commit<K extends keyof MutationInterface>(
    key: K,
    payload: Parameters<MutationInterface[K]>[1]
  ): ReturnType<MutationInterface[K]>;
} & Omit<ActionContext<SisState, SisState>, 'commit'>;

const actions: ActionTree<SisState, SisState> = {
  getData ({ state }: AugmentedActionContext, url: string) {
    return new Promise((resolve, reject) => {
      api.axios.get(`/api/${state.locale}/${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  postData ({ state }: AugmentedActionContext, { url, formData }: { url: string, formData: FormData }) {
    return new Promise((resolve, reject) => {
      api.axios({
        method: 'post',
        url: `/api/${state.locale}/${url}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e: AxiosError) => {
        reject(handleErrorResponse(e));
      });
    });
  },
  postEmptyData ({ state }: AugmentedActionContext, url: string) {
    return new Promise((resolve, reject) => {
      api.axios.post(`/api/${state.locale}/${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  putData ({ state }: AugmentedActionContext, { url, formData }: { url: string, formData: FormData }) {
    return new Promise((resolve, reject) => {
      api.axios({
        method: 'put',
        url: `/api/${state.locale}/${url}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e: AxiosError) => {
        reject(handleErrorResponse(e));
      });
    });
  },
};

const modules: ModuleTree<ModuleState> = {
  base: moduleStore,
};

export default new Vuex.Store({
  state: sisState,
  getters,
  mutations,
  actions,
  modules,
});
