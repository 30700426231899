<template lang="pug">
  .jwl-profile(v-if="hasUserData")
    .jwl-profile__menu-bar(@click="toggle")
      icon-angle-down.jwl-profile__user-toggle
      span.jwl-profile__user-name
        | {{currentUser.firstName}}
      common-profile-image.jwl-profile__user-icon(:image-url="currentUser.profilePhoto")

    .jwl-profile__flyout(:class="flyoutOpenClass")
      common-profile-image.jwl-profile__flyout-user-icon(:image-url="currentUser.profilePhoto")
      .jwl-profile__flyout-user-name
        | {{currentUser.firstName}} {{currentUser.lastName}}

      .jwl-profile__flyout-user-meta
        | ID: {{currentUser.id}}

      .jwl-profile__flyout-action
        .jwl-profile__flyout-log-out(@click="logout")
          | {{$t('general.logOut')}}

</template>

<script>
import { mapGetters } from 'vuex';
import CommonProfileImage from './CommonProfileImage.vue';

const IconAngleDown = () => import('../assets/fa-icons/solid/angle-down.svg');

export default {
  name: 'jwlProfile',
  components: {
    IconAngleDown,
    CommonProfileImage,
  },
  data () {
    return {
      open: false,
    };
  },
  methods: {
    toggle () {
      this.open = !this.open;
    },
    logout () {
      this.$store.dispatch('logOutUser');
      this.$router.push({ name: 'login' });
    },
  },
  computed: {
    flyoutOpenClass () {
      return this.open ? 'jwl-profile__flyout--open' : '';
    },
    ...mapGetters([
      'currentUser',
      'hasUserData',
    ]),
  },
  watch: {
    $route () {
      this.open = false;
    },
  },
};
</script>

<style lang="sass">
@import "src/styles/variables"
@import "../styles/mixins"

.jwl-profile
  align-items: center
  display: flex
  flex-flow: row
  justify-content: space-between
  position: relative

  &__menu-bar
    align-items: center
    cursor: pointer
    display: flex
    justify-content: space-between
    width: 100%

    @include mq
      display: none

  &__user
    align-items: center
    display: flex
    justify-content: center

  &__user-toggle
    @include ltr
      margin-right: .5rem

    @include rtl
      margin-left: .5rem

  &__user-name
    @include ltr
      margin-right: .25rem

    @include rtl
      margin-left: .25rem

  &__user-icon
    align-items: center
    background-color: $gray-800
    border-radius: 50%
    color: $white
    display: flex
    font-size: 1rem
    height: 1.5rem
    justify-content: center
    width: 1.5rem

  &__flyout
    background-color: $bg
    border-radius: $border-radius
    box-sizing: border-box
    display: grid
    grid-template: "profileImage name" auto "profileImage meta" 1.25rem "space actionButton" auto / 3rem 1fr
    opacity: 0
    padding: .5rem 1rem
    pointer-events: none
    position: absolute
    top: 2rem
    transform: translateY(-.25rem)
    transition: opacity .25s, transform .25s
    width: 16rem

    &--open
      opacity: 1
      pointer-events: auto
      transform: translateY(0)

    @include mq
      grid-template: "profileImage name" auto "profileImage meta" 1.25rem "space space" .5rem "actionButton actionButton" auto / 3rem 1fr
      opacity: 1
      pointer-events: auto
      position: relative
      top: 0
      transform: translateY(0)

    @include ltr
      right: 0

    @include rtl
      left: 0

  &__flyout-user-icon
    align-self: center
    font-size: 2rem
    grid-area: profileImage
    height: 3rem
    width: 3rem

  &__flyout-user-name
    align-self: flex-end
    grid-area: name

    @include ltr
      padding-left: .75rem

    @include rtl
      padding-right: .75rem

  &__flyout-user-meta
    color: $gray-600
    font-size: .85rem
    grid-area: meta

    @include ltr
      padding-left: .75rem

    @include rtl
      padding-right: .75rem

  &__flyout-action
    grid-area: actionButton
    padding-top: .5rem

    @include ltr
      padding-left: .75rem

    @include rtl
      padding-right: .75rem

    @include mq
      @include ltr
        padding-left: 0

      @include rtl
        padding-right: 0

  &__flyout-log-out
    background-color: var(--color-primary)
    border: .1rem solid var(--color-primary)
    border-radius: 1.2rem
    color: $white
    cursor: pointer
    text-align: center
    padding: .35rem 1.25rem
    transition: background-color .25s, color .25s

    @include mq
      padding: .15rem 1.25rem

    &:hover
      background-color: $white
      color: var(--color-primary)

</style>
