<template>
  <div class="jwl-header" v-if="currentUser">
    <div class="jwl-header__spacer" />
    <header class="jwl-header__header" :class="headerClass">
      <div class="jwl-header__user-bar">
        <router-link class="jwl-header__mobile-logo-container" :to="{ name: 'home' }">
          <logo-horizontal class="jwl-header__mobile-logo no-scale-svg" />

          <div class="jwl-header__mobile-menu-toggle" @click="toggleOpen" :class="toggleContainerClass">
            <div class="jwl-header__mobile-toggle" :class="toggleOpenClass" />
            <common-profile-image class="jwl-header__user-icon" :image-url="currentUser.profilePhoto" />
          </div>
        </router-link>
        <div class="jwl-header__user-container container" :class="mobileOpenClass">
          <div class="jwl-header__impersonate-container" v-if="currentUser.canImpersonate || currentUser.impersonated">
            <common-impersonate />
          </div>
          <div class="jwl-header__language-container" v-if="withLanguageSwitcher" @click.exact="openLanguages">
            <icon-angle-down class="jwl-header__language-toggle" />
            {{ $t(`languages.${$route.params.lang}`) }}

            <transition name="flyout">
              <ul class="jwl-header__languages" v-if="languageOpen" v-on-clickaway="closeLanguages">
                <li class="jwl-header__language">
                  <router-link class="jwl-header__language-link" :to="{ name: $route.name, params: { lang: 'en' } }" @click="closeLanguages">
                    {{ $t('languages.en') }}
                  </router-link>
                </li>
                <li class="jwl-header__language">
                  <router-link class="jwl-header__language-link" :to="{ name: $route.name, params: { lang: 'de' } }" @click="closeLanguages">
                    {{ $t('languages.de') }}
                  </router-link>
                </li>
              </ul>
            </transition>
          </div>

          <common-profile class="jwl-header__user" />
        </div>
      </div>

      <div class="jwl-header__logo-container container">
        <router-link class="jwl-header__logo" :to="{ name: 'home' }">
          <logo-horizontal class="no-scale-svg" />
        </router-link>
      </div>

      <nav class="jwl-header__nav-container">
        <div class="jwl-header__nav-content container">
          <ul class="jwl-header__nav-wrapper">
            <router-link class="jwl-header__logo-small" :to="{ name: 'home' }">
              <logo-small class="no-scale-svg" />
            </router-link>

            <li
              class="jwl-header__nav-elem"
              :class="menuNodeClass(menuNode)"
              v-for="menuNode in menuNodes"
              :key="`menu-node-${menuNode}`">
              <div class="jwl-header__nav-link jwl-header__nav-link--with-submenu"
                   v-if="menuNode === 'course' || menuNode === 'class'"
                   :class="activeMenuClass(menuNode)">
                <div class="jwl-header__nav-flyout-label" @click="toggleOverlay(menuNode)" v-t="`menu.${menuNode}`" />

                <div class="jwl-header__submenu" :id="`menu-flyout-${menuNode}`">
                  <common-menu-overlay class="container"
                                       @close-overlay="closeOverlay"
                                       :base-menu-name="menuNode === 'course' ? 'concentration_home' : menuNode" />
                </div>
              </div>

              <router-link class="jwl-header__nav-link" :to="{ name: menuNode, params: { lang: $route.params.lang } }" v-else>
                {{ $t(`menu.${menuNode}`) }}
              </router-link>
            </li>

            <li class="jwl-header__nav-elem" v-if="hasError">
              <router-link class="jwl-header__nav-link" :to="{ name: 'support_issue', params: { lang: $route.params.lang}, query: { issueType: 'system-error' } }">
                {{ $t(`menu.report-error`) }}
              </router-link>
            </li>
          </ul>
          <common-support-flyout class="jwl-header__nav-elem" />
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import CommonProfile from './CommonProfile.vue';
import CommonProfileImage from './CommonProfileImage.vue';

const CommonMenuOverlay = () => import('./CommonMenuOverlay.vue');
const CommonImpersonate = () => import('./CommonImpersonate.vue');
const LogoHorizontal = () => import('../assets/logo-horizontal.svg');
const LogoSmall = () => import('../assets/logo-small.svg');
const IconAngleDown = () => import('../assets/fa-icons/solid/angle-down.svg');
const CommonSupportFlyout = () => import('./CommonSupportFlyout.vue');

export default {
  name: 'jwlHeader',
  mixins: [
    clickaway,
  ],
  components: {
    IconAngleDown,
    CommonProfile,
    CommonProfileImage,
    CommonMenuOverlay,
    CommonImpersonate,
    LogoHorizontal,
    LogoSmall,
    CommonSupportFlyout,
  },
  props: {
    withLanguageSwitcher: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  data () {
    return {
      mobileOpen: false,
      languageOpen: false,
      otherOpen: '',
      headerVisible: true,
      headerThreshold: 25,
      lastScrollPos: 0,
      scrollDown: true,
    };
  },
  methods: {
    toggleOpen () {
      this.mobileOpen = !this.mobileOpen;
    },
    openLanguages () {
      this.languageOpen = true;
    },
    closeLanguages () {
      this.$nextTick(() => {
        if (this.languageOpen) {
          this.languageOpen = false;
        }
      });
    },
    menuNodeClass (modifier) {
      const classes = [`jwl-header__nav-elem--${modifier}`];

      if (modifier === this.otherOpen) {
        classes.push('jwl-header__nav-elem--open');
      }

      return classes.join(' ');
    },
    toggleOverlay (nodeName) {
      if (nodeName === this.otherOpen) {
        const scrollTarget = document.getElementById(`menu-flyout-${nodeName}`);
        enablePageScroll(scrollTarget);
        this.otherOpen = '';
      } else {
        if (this.baseConcentrations.dataAsFlatArray().length === 1) {
          const concentration = this.baseConcentrations.dataAsFlatArray()[0];
          this.$router.push({
            name: nodeName === 'course' ? 'concentration_home' : nodeName,
            params: {
              concentration: concentration.code,
            },
          });
        }

        const hasOther = this.otherOpen;

        this.otherOpen = nodeName;
        this.headerVisible = true;
        if (hasOther) {
          const oldScroll = document.getElementById(`menu-flyout-${hasOther}`);
          enablePageScroll(oldScroll);
        }
        const scrollTarget = document.getElementById(`menu-flyout-${nodeName}`);
        disablePageScroll(scrollTarget);
      }
    },
    closeOverlay () {
      enablePageScroll();
      this.otherOpen = '';
    },
    handleScroll () {
      const scrollOffset = window.scrollY;
      if (scrollOffset > this.lastScrollPos) {
        if (!this.scrollDown) {
          this.headerThreshold = scrollOffset + 25;
          this.scrollDown = true;
        }
      } else if (this.scrollDown) {
        this.scrollDown = false;
        this.headerThreshold = Math.max(100, scrollOffset - 400);
      }

      this.headerVisible = scrollOffset <= this.headerThreshold;

      this.lastScrollPos = scrollOffset;
    },
    activeMenuClass (menuNode) {
      const activeRoue = this.$route.name;
      if (menuNode === 'course') {
        const containingPaths = [
          'course',
          'concentration_home',
          'concentration_itinerary',
          'unit',
          'unit_overview',
          'unit_wbt',
          'submit_text',
          'submit_work',
          'discuss',
          'track',
        ];
        return containingPaths.includes(activeRoue)
          ? 'jwl-header__nav-link--active'
          : '';
      }
      if (menuNode === 'class') {
        const containingPaths = ['class_root', 'class', 'grade', 'grade_discussion'];
        return containingPaths.includes(activeRoue)
          ? 'jwl-header__nav-link--active'
          : '';
      }
      return '';
    },
  },
  computed: {
    headerClass () {
      return this.headerVisible ? '' : 'jwl-header__header--hidden';
    },
    mobileOpenClass () {
      return this.mobileOpen
        ? 'jwl-header__user-container--open'
        : '';
    },
    toggleContainerClass () {
      return this.mobileOpen
        ? 'jwl-header__mobile-menu-toggle--open'
        : '';
    },
    toggleOpenClass () {
      return this.mobileOpen
        ? 'jwl-header__mobile-toggle--open'
        : '';
    },
    hasError () {
      return this.errors.length > 0;
    },
    ...mapGetters([
      'menuNodes',
      'baseConcentrations',
      'currentUser',
    ]),
    ...mapState({
      errors: (state) => state.base.errors,
    }),
  },
  watch: {
    $route () {
      this.closeOverlay();
      this.closeLanguages();
    },
    headerVisible (targetVal) {
      if (targetVal) {
        document.body.classList.remove('jwl-menu-hidden');
      } else {
        document.body.classList.add('jwl-menu-hidden');
      }
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="sass">
@import "../styles/mixins"
@import "src/styles/variables"

.jwl-header
  $root: &

  --user-header-height: 4.5rem

  &__spacer
    height: var(--menu-height)
    width: 100%

  &__header
    background-color: $white
    box-shadow: $gray-300 0 .125em .125em
    height: var(--menu-height)
    left: 0
    margin-bottom: 1rem
    position: fixed
    top: 0
    transition: top .25s
    width: 100%
    z-index: 1000

    &--hidden
      top: calc((var(--menu-height) - var(--nav-bar-height)) * -1)

      #{$root}__logo-small
        opacity: 1
        pointer-events: auto
        transform: translateX(0)

  &__user-bar
    background-color: $gray-100
    height: 1.75rem
    margin-bottom: .5rem
    padding: .25rem 1rem
    position: relative

    @include mq
      background-color: $white
      height: var(--user-header-height)
      margin-bottom: 0
      padding: 0 0

  &__mobile-logo-container
    display: none

    @include mq
      align-items: center
      box-sizing: border-box
      display: flex
      height: var(--user-header-height)
      justify-content: space-between
      width: 100%

      @include ltr
        padding-left: 1.5rem

      @include rtl
        padding-right: 1.5rem

      svg
        height: auto
        max-height: 3rem
        max-width: 100%

  &__mobile-menu-toggle
    align-items: center
    display: flex
    height: 100%
    justify-content: flex-start
    padding: 0 1.5rem 0 .75rem
    transition: background-color .25s

    &--open
      background-color: $gray-100

  &__mobile-toggle
    align-items: center
    align-self: center
    color: $grey
    cursor: pointer
    display: flex
    grid-area: toggle
    height: .6rem
    justify-content: center
    justify-self: center
    margin-right: .5rem
    position: relative
    transition: height .2s
    width: .95rem

    &::before,
    &::after
      background-color: currentColor
      border-radius: .1rem
      content: ''
      display: block
      height: .2rem
      position: absolute
      transition: transform .2s, color .2s
      width: .62rem

    &::before
      left: 0
      transform: rotate(45deg)
      transform-origin: center center

    &::after
      right: 0
      transform: rotate(-45deg)
      transform-origin: center center

    &--open
      color: var(--menu-color)

      &::before
        transform: rotate(-45deg)

      &::after
        transform: rotate(45deg)

  &__user-icon
    font-size: 1.25rem
    height: 2rem
    width: 2rem

  &__user-container
    align-items: center
    display: flex
    justify-content: flex-end
    margin: 0 auto
    max-width: $container-width
    width: 100%

    @include mq
      background-color: $gray-100
      box-sizing: border-box
      display: flex
      flex-flow: row wrap
      height: 0
      justify-content: space-between
      left: 0
      opacity: 0
      overflow: hidden
      padding: 0 0
      pointer-events: none
      position: absolute
      top: 100%
      transform: translateY(-.25rem)
      transition: opacity .25s, transform .25s
      width: 100vw !important
      z-index: 100

      &--open
        height: auto
        opacity: 1
        padding: 1rem 1.5rem
        pointer-events: auto
        transform: translateY(0)

  &__impersonate-container
    display: var(--toggle-state)

    @include mq
      margin-bottom: 1rem
      order: 2

      .jwl-impersonate
        @include ltr
          margin-right: 0

        @include rtl
          margin-left: 0

  &__language-container
    align-items: center
    background-color: $gray-300
    border-radius: $border-radius
    cursor: pointer
    display: flex
    flex-flow: row nowrap
    justify-content: center
    padding: .25rem .75rem
    position: relative

    @include ltr
      margin-right: 1.5rem

    @include rtl
      margin-left: 1.5rem

    @include mq
      margin-bottom: 1rem
      order: 1
      z-index: 50

      @include ltr
        margin-right: 1rem

      @include rtl
        margin-left: 1rem

  &__language-toggle
    @include ltr
      margin-right: .5rem

    @include rtl
      margin-left: .5rem

  &__languages
    background-color: $gray-300
    border-radius: 3px
    list-style: none
    opacity: 1
    padding: .25rem .5rem
    position: absolute
    top: 1.25rem
    transform: translateY(0)
    transition: opacity .25s, transform .25s
    min-width: calc(100% - 2.4rem)

    &.flyout-enter,
    &.flyout-leave-to
      opacity: 0
      transform: translateY(-.25rem)

    @include ltr
      left: 0
      padding-left: 1.9rem

    @include rtl
      padding-right: 1.75rem
      right: 0

  &__language-link
    color: $gray-800
    text-decoration: none
    transition: color .25s

    &.router-link-active
      text-decoration: underline

    &:hover
      color: var(--color-primary)
      text-decoration: none

  &__user
    display: flex

    @include mq
      order: 3
      width: 100%

  &__logo-container
    display: flex
    flex-direction: row
    margin-bottom: .5rem

    @include mq
      display: none

    svg
      height: 6rem

  &__logo-small
    left: -2.5rem
    opacity: 0
    pointer-events: none
    position: absolute
    transform: translateY(-1rem)
    transition: opacity $transition-duration, transform $transition-duration

    svg
      height: 1.75rem
      width: 1.75rem

  &__nav-container
    align-items: center
    display: flex
    flex-direction: row
    height: var(--nav-bar-height)
    justify-content: flex-start
    max-width: 100vw
    overflow-x: auto
    overflow-y: hidden

    @include mq
      background-color: $gray-100

  &__nav-content
    display: flex
    justify-content: space-between

  &__nav-wrapper
    display: flex
    flex-flow: row nowrap
    align-items: baseline
    justify-content: flex-start
    margin: 0 0
    padding: 0 0
    position: relative

  &__nav-elem
    @include propertyColor('color', '.router-link-active')
    --link-color: #{$gray-700}

    list-style: none
    margin-right: 1rem
    padding: .25em 0

    .router-link-active,
    & #{$root}__nav-link--active
      color: var(--active-link-color)
      font-weight: $font-weight-normal

    &--open
      --link-color: var(--active-link-color)

      &::after
        border-bottom-color: var(--active-link-color)

      #{$root}__nav-flyout-label
        position: relative

        &::after
          border-bottom: .5rem solid var(--link-color)
          border-left: .5rem solid transparent
          border-right: .5rem solid transparent
          bottom: -.5rem
          content: ""
          height: 0
          left: calc(50% - .25rem)
          position: absolute
          width: 0

          @include mq
            bottom: -.75rem

      #{$root}__submenu
        height: calc(100% - var(--menu-height))
        opacity: 1
        overflow: auto
        pointer-events: all
        transition-delay: .2s

    &:not(:last-child) #{$root}__nav-link
      @include ltr
        border-right: 1px solid $gray-300
        padding-right: 1rem

      @include rtl
        border-left: 1px solid $gray-300
        padding-left: 1rem

  &__nav-link
    color: var(--link-color)
    text-decoration: none
    transition: color $transition-time

    &:hover
      color: var(--active-link-color)
      text-decoration: none

    &.router-link-active
      font-weight: $font-weight-normal

    &--with-submenu
      cursor: pointer
      position: relative

      &::after
        border-bottom: .3rem solid transparent
        border-left: .3rem solid transparent
        border-right: .3rem solid transparent
        bottom: -.3rem
        content: ""
        position: absolute
        left: calc(50% - .6rem)

  &__submenu
    backdrop-filter: blur(3px)
    background-color: transparentize($white, .1)
    border-top: .2rem solid var(--active-link-color)
    cursor: default
    height: 0
    left: 0
    opacity: 0
    overflow: hidden
    pointer-events: none
    position: fixed
    top: var(--menu-height)
    transition: opacity .2s, height .2s
    transition-delay: .2s
    width: 100%

</style>
